import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{path: 'login', loadChildren: () => import('./View/Login/LoginViewModule').then(m => m.LoginViewModule)},
	{path: '', loadChildren: () => import('./View/Main/AppViewModule').then((m) => m.AppViewModule)},
	{
		path: 'error',
		loadChildren: () => import('./View/Error/ApplicationErrorModule').then((m) => m.ApplicationErrorModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
