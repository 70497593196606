import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AppSetting } from '../AppSetting';


/**
 * AppSingletonUtil.ts
 * アプリでシングルトンとして取り扱うオブジェクトへのアクセスを提供する。
 */
export class AppSingletonUtil {

	// 本体
	private static instance: AppSingletonUtil;

	// HTTPClient (アプリ起動時にComponentで作成)
	private static httpClient: HttpClient;
	// AngularのActivatedRoute(アプリ起動時にComponentで作成)
	private static route: ActivatedRoute;

	// IonicのPlatformサービスクラス(アプリ起動時にComponentで作成)
	private static platform: Platform;

	// アプリでユニークのトークン
	private static tokenString: string;
	// ユーザー情報（ログインしてホーム表示する時に取得して設定）
	private static userInfo: any;
	// SkyWayのサービスに接続するために発行されたID
	private static skywayId: string;

	/**
	 * loginInfoClear
	 * ログイン状態のクリア
	 */
	public static loginInfoClear(){
		this.tokenString = null;
		this.userInfo = null;
		this.skywayId = null;
	}

	/**
	 * setHttpClient
	 * シングルトンで使うHTTP Clientを保持する。
	 * HTTPClient (アプリ起動時にComponentで作成)
	 * @param hc HttpClientのインスタンス
	 */
	public static setHttpClient(hc: HttpClient) {
		this.httpClient = hc;
	}
	/**
	 * getHttpClient
	 * HttpClientを取得（アプリでシングルトン）
	 */
	public static getHttpClient(): HttpClient {
		// HACK: setされてないとエラーでる
		return this.httpClient;
	}

	/**
	 * setActivatedRoute
	 * シングルトンで使うRouteを保持する
	 * URLへのアクセス等に使用する
	 * ActivatedRoute（アプリ起動時にComponentで作成）
	 * @param route AngularのActivatedRoute
	 */
	public static setActivatedRoute(route: ActivatedRoute) {
		this.route = route;
	}
	/**
	 * getActivatedRoute
	 * Routeを取得する（アプリでシングルトン）
	 * @returns AngularのActivatedRoute
	 */
	public static getActivatedRoute(): ActivatedRoute {
		return this.route;
	}

	/**
	 * setPLatform
	 * シングルトンで使うPlatformを保持する。
	 * Platform（アプリ起動時にComponentで作成）
	 * @param platform Platformのインスタンス
	 */
	public static setPlatform(platform: Platform) {
		this.platform = platform;
	}
	/**
	 * getPlatform
	 * Platformを取得（アプリでシングルトン）
	 */
	public static getPlatform(): Platform {
		return this.platform;
	}

	/**
	 * setToken
	 * アプリトークンを保持
	 * @param t アプリトークン
	 */
	public static setToken(t: string) {
		this.tokenString = t;
	}
	/**
	 * getToken
	 * アプリトークンを取得
	 * @return アプリトークン
	 */
	public static getToken(): string {

		// デバッグモードならダミーのユーザ情報を返す
		if ( AppSetting.DEBUG_MODE_AUTHTRUE ) {
			return AppSetting.DUMMY_APP_TOKEN;
		}

		return this.tokenString;
	}

	/**
	 * setUserInfo
	 * シングルトンで使うUserInfoを保持する。
	 * ユーザー情報（ログインしてホーム表示する時に取得して設定）
	 * @param ui ユーザ情報
	 */
	public static setUserInfo(ui: any) {
		this.userInfo = ui;
	}
	/**
	 * getUserInfo
	 * ユーザー情報を取得（ログインしてホーム表示する時に取得して設定）
	 */
	public static getUserInfo() {
		return this.userInfo;
	}

	/**
	 * setSkywayId
	 * SkyWayに接続して使用するために必要なIDを保持
	 * @param id 接続するID
	 */
	public static setSkywayId(id: string) {
		this.skywayId = id;
	}
	/**
	 * getSkywayId
	 * SkyWayのサービスに接続するIDを取得
	 * @return SkyWayのID
	 */
	public static getSkywayId(): string {
		return this.skywayId;
	}





	// 本体の取得。 ブート時に入れておく。
	public static getInstance(): AppSingletonUtil {
		if (!this.instance) {
			this.instance = new AppSingletonUtil();
		}
		return this.instance;
	}
}
