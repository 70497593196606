import { environment } from '../environments/environment';
export class AppSetting {

	///////////////////////////////////////////////////////////
	// 開発用
	// ★必要があれば、再利用でなく専用のフラグを作る事
	public static readonly DEBUG_MODE_AUTHTRUE 			=  environment.DEBUG_MODE_AUTHTRUE; // 認証をスルーする
	public static readonly DEBUG_MODE_ADMIN    			=  environment.DEBUG_MODE_ADMIN; // ユーザーの種類がAdmin
	public static readonly DEBUG_MODE_DUMYDATA 			=  environment.DEBUG_MODE_DUMYDATA; // DAOでサーバから取らず、ダミーデータを返す。
	public static readonly DEBUG_MODE_USER_ANSWER_NOSAVE=  environment.DEBUG_MODE_USER_ANSWER_NOSAVE; // DAAOでユーザの回答結果をサーバー送信をしない。

	public static readonly DUMMY_APP_TOKEN = environment.DUMMY_APP_TOKEN; //　認証スルー時に設定されるダミートークン

	// public static readonly LOGIN_URL = environment.LOGIN_URL; // ログインURL

	///////////////////////////////////////////////////////////
	// 各種設定

	// アプリのバージョンストリング
	public static readonly APP_VESION_STRING = environment.APP_VESION_STRING;

	// HTTP Requestのリトライ回数
	public static readonly HTTP_REQUEST_RETRY = environment.HTTP_REQUEST_RETRY;

	// ローカルストレージ保存用キー
	public static readonly LOCAL_STORAGE_KEY_TOKEN = environment.LOCAL_STORAGE_KEY_TOKEN; // アプリトークン

}
