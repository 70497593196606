import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AppSingletonUtil } from './Util/AppSingletonUtil';
import { environment } from './../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'Tarkov';

	/**
	 * constructor
	 *
	 * @param platform Ionic Platform
	 * @param splashScreen Ionic SplashScreen(Angular Cordova)
	 * @param statusBar Ionic StatusBar(Angular Cordova)
	 * @param hc Angular HttpClient
	 * @param route: Angular ActivatedRoute
	 */
	constructor(
		private platform: Platform,
		private hc: HttpClient,
		private route: ActivatedRoute,
	) {
		// アプリの初期化を行う
		this.initializeApp();

	}


	/**
	 * initializeApp
	 *
	 * アプリの初期化
	 */
	 private async initializeApp() {

		// シングルトンのHttpClientを用意。DAOはこれ共通で動作してる
		AppSingletonUtil.setHttpClient(this.hc);
		// Ionic の環境状態を取得するオブジェクト Platform
		AppSingletonUtil.setPlatform(this.platform);
		// クエリストリング取得したりするActivatedRoute。
		AppSingletonUtil.setActivatedRoute(this.route);

        // 履歴変化のイベントに、空のブラウザ履歴をpushして履歴移動を打ち消すクロージャをいれておく。
		// @see https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
		// @see https://developer.mozilla.org/ja/docs/Web/API/WindowEventHandlers/onpopstate
		window.onpopstate = () => { history.pushState(null, null, ''); };

		// 状態が完了になるまでまって、アプリを開始する
		this.platform.ready().then(() => {

			history.pushState(null, null, null);

		});
	}
}
